* {
  box-sizing: border-box;
  margin: 0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-content {
  content: url("./assets/SizeChanger.png");
  width: 20px;
  height: 20px;
  margin-top: 100%;
}
.custom-select .ant-select-selector {
  background: #f3f4f6 !important;
  padding: 20px !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.5rem !important;
}

.ant-select-selector {
  cursor: pointer !important;
}

.custom-select {
  height: 53px !important;
  border: none !important;
}
.custom-select .ant-select-selection-search {
  padding-left: 9px !important;
}

.custom-table thead th,
.custom-table tbody td {
  border-right: 1px solid #d9d9d9; /* Add a right border to each cell */
}

.custom-table tbody tr:last-child td {
  border-bottom: 1px solid #d9d9d9; /* Add a bottom border to the last row */
}

.custom-table thead th:first-child,
.custom-table tbody td:first-child {
  border-left: 1px solid #d9d9d9; /* Add a left border to the first column */
}

.custom-table thead th {
  border-top: 1px solid #d9d9d9; /* Add a top border to the header cells */
}

.custom-table {
  border-collapse: separate; /* Ensure borders do not overlap */
}
.ant-pagination-options .ant-select-selection-search {
  display: none !important;
}
.custom-antd-select .ant-select-selector {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ant-select-item-option-state {
  display: none !important;
}
.ant-select-selection-item-content > div > img {
  display: none !important;
}
.row-black {
  background-color: rgb(224 242 254);
  cursor: pointer;
}

.row-white {
  background-color: white;
  color: black;
  cursor: pointer;
}
.custom-table .ant-table-tbody > tr.row-black td {
  background-color: rgb(239, 249, 255);
  cursor: pointer;
}

.custom-table .ant-table-tbody > tr.row-white td {
  background-color: white;
  color: black;
  cursor: pointer;
}

.ant-table-cell-row :hover {
  background-color: inherit !important;
}
/* .custom-select .ant-select-selection-overflow {
  height: 100% !important;
}
.custom-select
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  width: 100% !important;
}
.custom-select .ant-select-selection-search {
  width: 100% !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.custom-select .ant-select-selector {
  padding-left: 10px !important;
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 640px) {
  .custom-table .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 0.6;
  }
}
